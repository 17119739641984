import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import HttpPage from './pages/Request';
// import PreMint from './pages/preMint';
import Mint from './pages/mint';
import Gamefi from './pages/gamefi';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/premint" element={<PreMint />} /> */}
        <Route path="/mint" element={<Mint />} />
        <Route path="/gamefi" element={<Gamefi />} />
        <Route path="/NFTstake" element={<Gamefi />} />
        <Route path="/http" element={<HttpPage />} />
      </Routes>
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
    </Router>
  );
}

export default App;
