/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { linkWallet, NxWeb3 } from '../../libs/NxWeb3';
import { toast } from 'react-toastify';
import './index.scss'




const Gamefi = (): JSX.Element => {
  const [address, setAddress] = useState('');
  const [Quantity, setQuantity] = useState(3)
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [count, setCount] = useState(0)
  const maxQuantity = 10;
  const [tipHide, setTipHide] = useState(false)
  const hideAddress = (str: string) => {

    return str.substr(0, 5) + "****" + str.substr(str.length - 4);
  }

  useEffect(() => {
    getTotal()
    window.addEventListener('click', () => {
      setTipHide(false)
    })
  }, [])


  const getTotal = async () => {
    const total = await NxWeb3.instance.getTotalSupply()
    setCount(total)
    console.log('%c🀁 total', 'color: #0088cc; font-size: 20px;', total);
    console.log('%c🀅 total', 'color: #00bf00; font-size: 20px;', total);
  }
  const connectMetaMask = async () => {
    const result = await linkWallet(() => {
      setAddress(NxWeb3.instance.accountAddress);
    })

    setIsConnected(result);
    if (NxWeb3.instance.isInited) {
      setAddress(NxWeb3.instance.accountAddress);
    }
  }


  const mintFun = async () => {
    if (!address || !isConnected) {
      // showToast('Plase connect wallet.', 0);
      connectMetaMask()
      return;
    }

    // await NxWeb3.instance.switchChian()
    setIsLoading(true);

    const approvecall = await NxWeb3.instance.approve(Quantity);
    if (approvecall === true) {
      const result = await NxWeb3.instance.mint(Quantity);
      if (result === true) {
        toast.success('Successed.');
      } else {
        toast.error(result.message);
      }
    } else {
      toast.error(approvecall.message);
    }


    setIsLoading(false);

  }





  return (
    <>
      <div className="gamefiComp">
        <div className="header w-full px-1 md:px-5 py-sm md:py-1 sticky top-0">
          <div className="flex items-center justify-end md:justify-between">
            <a href='/' className="logo flex items-center justify-center hidden md:flex">
              <img src={require('../../assets/img/logo.png').default} alt="" className=" w-90 md:w-70" />
              <p className="flex items-center justify-center text-head md:text-2xl top-1 ml-1 relative">
                <span className="font-BBH text-blue line-height">Twitter</span>
                <span className="font-BBH text-gold line-height">Doge-NFT</span>
              </p>
            </a>
            <div className="flex animate-fade-in flex-row flex items-center justify-end">
              <div className="block mx-auto text-center">
                <div className="relative"
                  onClick={(e) => {
                    e.stopPropagation()
                    setTipHide(true)
                    connectMetaMask()
                  }}
                >
                  <button className="cta-btn-transparent mobile-menu w-inline-block md:py-1">
                    <div className="cta-btn__txt">{address !== '' ? hideAddress(address) : "Connect Wallet"}</div>
                    <div className="cta-btn-transparent__gradhide"></div>
                    <div className="cta-btn-transparent__gradhide-red"></div>
                    <div className="cta-btn-transparent__animbox"></div>

                  </button>
                  {
                    tipHide && isConnected
                      ? <div className="disconnect" onClick={(e) => {
                        e.stopPropagation()
                        setTipHide(false)
                      }}>
                        disconnect
                      </div>
                      : ''
                  }

                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="mint">

          <div className="container">
            <h1>Under development, stay tuned</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gamefi;